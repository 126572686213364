export function toStringify(arr) {
  let response = [];
  arr.forEach((element) => {
    response.push(JSON.stringify(element));
  });

  return response;
}

export function parseMoney(value) {
  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  });
  return formatter.format(value);
}
