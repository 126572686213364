<template>
  <div
    class="fixed z-40 top-0 bottom-0 left-0 right-0 h-full w-full bg-black bg-opacity-50 flex justify-end"
  >
    <div
      class="w-3/4 h-full cursor-pointer"
      @click="$emit('close')"
      :class="step === 8 ? 'w-1/4' : ''"
    ></div>
    <div
      class="w-1/4 h-full bg-white rounded-l-2xl p-10 overflow-auto"
      :class="step === 8 ? 'w-3/4' : ''"
    >
      <div class="flex items-center justify-between">
        <h1 class="text-black font-bold text-xl">
          {{ modalTitle }}
        </h1>
        <div
          class="bg-red-500 bg-opacity-30 p-5 rounded-full cursor-pointer"
          @click="$emit('close')"
        >
          <CloseIcon />
        </div>
      </div>
      <div class="text-transparent">{{ step }}</div>
      <template v-if="step === 1">
        <ValidationObserver v-slot="{ invalid }">
          <form @submit.prevent="submitStepOne" class="relative mt-10">
            <ValidationProvider name="user" v-slot="{ errors }">
              <label for="form" class="block mb-2"
                >Select User to fill the forms</label
              >
              <select
                id="form"
                v-model="from_staff_or_customer"
                placeholder="Front Desk Office or Customer "
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border-red-400 border' : ''"
              >
                <option value="1">Customer</option>
                <option value="0">Front Desk Officer</option>
              </select>
              <span class="block text-red-400 mt-3" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <p class="mt-5">
              Please pick your prefer style of filling the corpse admission
              forms. You can choose to fill it as a system user or display the
              form on an external screen for the customer to fix.
            </p>
            <button
              class="w-full mt-52 text-white py-3 rounded-md bg-opacity-80 transition-all hover:bg-opacity-100 disabled:cursor-not-allowed"
              :class="invalid ? 'bg-gray-300' : 'bg-primary'"
              :disabled="invalid || submitting"
            >
              {{ submitting ? "Please wait..." : "Continue" }}
            </button>
          </form>
        </ValidationObserver>
      </template>
      <addmission-data
        v-else-if="step === 2"
        :corpse_id="corpse.corpse_id"
        @created="admissionData"
      />
      <template v-else-if="step === 3">
        <form @submit.prevent="generateTagNumber" class="text-left mt-10">
          <div class="mb-5">
            <label class="block mb-2">Name of Deceased</label>
            <input
              type="text"
              class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
              v-model="corpse.name_of_deceased"
              disabled
            />
          </div>
          <div class="mb-5">
            <label class="block mb-2">Age of Deceased</label>
            <input
              type="text"
              class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
              v-model="corpse.age_of_deceased"
              disabled
            />
          </div>
          <div class="mb-5">
            <label class="block mb-2">Gender</label>
            <input
              type="text"
              class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
              v-model="corpse.gender_of_deceased"
              disabled
            />
          </div>
          <div class="mb-5">
            <label class="block mb-2">Date</label>
            <input
              type="text"
              class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
              v-model="corpse.date_of_admission"
              disabled
            />
          </div>
          <button
            class="w-full mt-24 bg-primary text-white py-3 rounded-md bg-opacity-80 transition-all hover:bg-opacity-100 disabled:cursor-not-allowed"
            :disabled="submitting"
          >
            {{ submitting ? "Please wait..." : "Generate Tag No" }}
          </button>
        </form>
      </template>
      <template v-else-if="step === 4">
        <div ref="toPrint">
          <p class="font-barcode text-center" style="font-size: 5rem">
            {{ corpse.corpse_tag || "Hello" }}
          </p>
          <p class="text-center -mt-10 mb-5">
            A Tag number <b>{{ corpse.corpse_tag }}</b> has been generated
            successfully.
          </p>
          <div class="mb-5">
            <label class="block mb-2">Name of Deceased</label>
            <ValidationProvider name="Name" rules="required">
              <input
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                v-model="corpse.name_of_deceased"
                disabled
              />
            </ValidationProvider>
          </div>
          <div class="mb-5">
            <label class="block mb-2">Age of Deceased</label>
            <ValidationProvider name="Name" rules="required">
              <input
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                v-model="corpse.age_of_deceased"
                disabled
              />
            </ValidationProvider>
          </div>
          <div class="mb-5">
            <label class="block mb-2">Gender</label>
            <ValidationProvider name="Name" rules="required">
              <input
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                v-model="corpse.gender_of_deceased"
                disabled
              />
            </ValidationProvider>
          </div>
          <div class="mb-5">
            <label class="block mb-2">Date</label>
            <ValidationProvider name="Name" rules="required">
              <input
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                v-model="corpse.date_of_admission"
                disabled
              />
            </ValidationProvider>
          </div>
        </div>
        <button
          @click="printTag()"
          class="w-full mt-20 text-black bg-gray-200 py-3 rounded-md"
        >
          Click here to Print Tag
        </button>
        <button
          v-if="corpse.embalmment_status === 1"
          @click="step += 1"
          class="w-full mt-10 bg-primary text-white py-3 rounded-md"
        >
          Continue to Embalment
        </button>
        <button
          v-else-if="corpse.funeral_service === 1"
          @click="step = 6"
          class="w-full mt-10 bg-primary text-white py-3 rounded-md"
        >
          Continue to Funeral Service
        </button>
        <button
          v-else
          @click="step = 7"
          class="w-full mt-10 bg-primary text-white py-3 rounded-md"
        >
          Continue to Billing Sheet
        </button>
      </template>
      <template v-else-if="step === 5">
        <ValidationObserver v-slot="{ invalid }">
          <form @submit.prevent="submitEmbalment" class="mt-10">
            <div class="mb-5">
              <label class="block mb-2">I (Please enter your name here)</label>
              <ValidationProvider
                name="Name"
                rules="required"
                v-slot="{ errors }"
              >
                <input
                  type="text"
                  placeholder="Name"
                  class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                  :class="errors[0] ? 'border-red-400 border' : ''"
                  v-model="embalment.name"
                />
                <span class="block text-red-400 mt-3" v-if="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="mb-5">
              <label class="block mb-2"
                >Of Address (Please enter your address here)</label
              >
              <ValidationProvider
                name="Address"
                rules="required"
                v-slot="{ errors }"
              >
                <input
                  type="text"
                  placeholder="Address"
                  class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                  :class="errors[0] ? 'border-red-400 border' : ''"
                  v-model="embalment.address"
                />
                <span class="block text-red-400 mt-3" v-if="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="mb-5">
              <label class="block mb-2">Name of Deceased</label>
              <ValidationProvider
                name="Name"
                rules="required"
                v-slot="{ errors }"
              >
                <input
                  type="text"
                  placeholder="Name of Deceased"
                  class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                  :class="errors[0] ? 'border-red-400 border' : ''"
                  v-model="embalment.name_of_deceased"
                />
                <span class="block text-red-400 mt-3" v-if="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="mb-5">
              <label class="block mb-2">Relationship with the Deceased</label>
              <ValidationProvider
                name="Relationship"
                rules="required"
                v-slot="{ errors }"
              >
                <input
                  type="text"
                  placeholder="Relationship with the Deceased"
                  class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                  :class="errors[0] ? 'border-red-400 border' : ''"
                  v-model="embalment.relationship_deceased"
                />
                <span class="block text-red-400 mt-3" v-if="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="mb-5">
              <label class="block mb-2">Phone Number</label>
              <ValidationProvider
                name="Phone Number"
                rules="required|length:11"
                v-slot="{ errors }"
              >
                <input
                  type="tel"
                  placeholder="Phone Number"
                  class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                  :class="errors[0] ? 'border-red-400 border' : ''"
                  v-model="embalment.phone_number"
                />
                <span class="block text-red-400 mt-3" v-if="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="mb-5">
              <label class="block mb-2">Email Address</label>
              <ValidationProvider
                name="Email Address"
                rules="required|email"
                v-slot="{ errors }"
              >
                <input
                  type="email"
                  placeholder="Email Address"
                  class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                  :class="errors[0] ? 'border-red-400 border' : ''"
                  v-model="embalment.email"
                />
                <span class="block text-red-400 mt-3" v-if="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <button
              class="w-full mt-24 text-white py-3 rounded-md bg-opacity-80 transition-all hover:bg-opacity-100 disabled:cursor-not-allowed"
              :class="invalid ? 'bg-gray-300' : 'bg-primary'"
              :disabled="invalid && submitting"
            >
              {{ submitting ? "Please wait..." : "Continue" }}
            </button>
          </form>
        </ValidationObserver>
      </template>
      <funeral-service
        :corpse="corpse"
        @created="funeralService"
        v-else-if="step === 6"
      />
      <billing-sheet-modal
        v-else-if="step === 7"
        :corpse="corpse"
        :name="embalment.name || corpse.contact_person"
        @created="billingSheet"
      />
      <invoice v-else :invoice="invoice" />
    </div>
  </div>
</template>

<script>
import { firststep, thirdstep, createembalment } from "@/services/addmission";
import { parseMoney } from "@/utils/helpers";

export default {
  name: "AddNewCorpse",
  components: {
    CloseIcon: () => import("@/assets/icons/CloseIcon.vue"),
    FuneralService: () => import("./FuneralService.vue"),
    BillingSheetModal: () => import("./BillingSheetModal.vue"),
    Invoice: () => import("./Invoice.vue"),
    AddmissionData: () => import("./AddmissionData.vue"),
  },
  props: ["push", "corpseID"],
  data() {
    return {
      corpse: {
        corpse_id: "", // Gotten from BE
        image: "",
        name_of_deceased: "",
        age_of_deceased: "",
        gender_of_deceased: "",
        cause_of_death: "",
        time_of_admission: "",
        date_of_admission: "",
        contact_person: "",
        contact_person_phone: "",
        fridge_number: "",
        embalmment_status: false,
        funeral_service: false,
        repatriation: false,
        personal_possession_on_arrival: "",
        comment: "",
      },
      embalment: {
        corpse_id: "",
        name: "",
        address: "",
        name_of_deceased: "",
        relationship_deceased: "",
        phone_number: "",
        email: "",
        signature: false,
      },
      invoice: {},
      step: 1,
      from_staff_or_customer: "",
      submitting: false,
    };
  },
  methods: {
    async submitStepOne() {
      this.submitting = true;
      try {
        const response = await firststep({
          from_staff_or_customer: this.from_staff_or_customer,
        });
        this.corpse.corpse_id = response.data.corpse.corpse_id.toString();
        if (this.from_staff_or_customer === "1") {
          alert(`New corpse created with ID: ${this.corpse.corpse_id}`);
          this.$toast.info("Please ask the customer to fill in the details");
          this.$emit("close");
        } else {
          this.step += 1;
        }
        this.submitting = false;
      } catch (error) {
        if (error.response.status === 403) {
          this.$toast.error(error.response.data.message || error);
        } else {
          this.$toast.error(error.response.data.message || error);
          console.log(error);
        }
        this.submitting = false;
      }
    },
    async generateTagNumber() {
      this.submitting = true;
      try {
        const response = await thirdstep({ corpse_id: this.corpse.corpse_id });
        this.corpse = response.data.corpse;
        this.step += 1;
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        console.log(error);
      }
      this.submitting = false;
    },
    async submitEmbalment() {
      this.submitting = true;
      this.embalment.corpse_id = this.corpse.corpse_id;
      this.embalment.signature = this.embalment.signature.toString();
      try {
        const response = await createembalment(this.embalment);
        this.corpse = response.data.corpse;
        this.embalment = response.data.embalment;
        if (this.corpse.funeral_service) {
          this.step += 1;
        } else {
          this.step = 7;
        }
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
      }

      this.submitting = false;
    },
    admissionData(e) {
      this.corpse = e;
      this.step = 3;
    },
    funeralService(e) {
      console.log(e);
      this.step = 7;
    },
    billingSheet(e) {
      this.invoice = e;
      this.step = 8;
    },
    printTag() {
      // const toPrint = this.$refs.toPrint.innerHTML;
      const winPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      winPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            <style rel="stylesheet">
              body { display: flex; justify-content: center; align-items: center; font-size: 20px; }
              .main-div { border: 1px solid black; width: 70%; padding: 20px; height: 100%; }
              h1 { font-size: 30px; text-align: center; margin: 0 0 20px;}
            </style>  
          </head>
          <body>
            <div class="main-div">
              <h1>TOS Tag form</h1>
              Corpse name: ${this.corpse.name_of_deceased}<br />
              Tag number: ${this.corpse.corpse_tag}<br />
              Corpse gender: ${this.corpse.gender_of_deceased}<br />
              Corpse Age: ${this.corpse.age_of_deceased}<br />
              Date of Admission: ${this.corpse.date_of_admission}<br />
            </div>
          </body>
        </html>
      `);
      winPrint.document.close();
      winPrint.focus();
      winPrint.print();
      winPrint.close();
    },
    parseCash(value) {
      return parseMoney(value);
    },
  },
  computed: {
    modalTitle() {
      switch (this.step) {
        case 1:
          return "Create Profile";
        case 2:
          return "Create Profile";
        case 3:
          return "Generate Tag Form";
        case 4:
          return "Generate Tag Form";
        case 5:
          return "Embalment form";
        case 6:
          return "Funeral Services";
        case 7:
          return "Billing Sheet";
        default:
          return "Invoice for Morgue Services";
      }
    },
  },
  mounted() {
    if (this.push === 2) {
      this.step = this.push;
      this.corpse.corpse_id = this.corpseID;
    }
  },
};
</script>
